import axios from "@/axios/axios-api";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async fetchAgentesFacturacionByQuery(context, payload) {
    if (payload.input && payload.input.length >= 1) {
      const response = await axios.get(
        `api/taxonomy/findAgentesFacturacion?search=${payload.input}`
      );
      return response.data;
    }
  },
  async fetchComprobantesCtaCteFacturacionOS(context, payload) {
    const response = await axios.post(
      "api/facturacionOS/comprobantesCuentaCorrienteFacturacionOS",
      payload
    );
    return response.data.data;
  },
  async getComprobanteFacturacionOSById(context, payload) {
    const response = await axios.post(
      "api/facturacionOS/detalleCompFacturaOSById",
      payload
    );
    return response.data.data;
  },
  async getComprobanteCancelaFacturacionOSById(context, payload) {
    const response = await axios.post(
      "api/facturacionOS/DetalleCompCancelaFacturaOSById",
      payload
    );
    return response.data.data;
  },
  async getAllTipoComprobantesFacturacionOS(context, payload) {
    const response = await axios.get(
      `api/taxonomy/getAllTipoComprobantesFacturacionOS/${payload}`
    );
    return response.data.data;
  },
  async getComprobantesFacturacionOSByFilters(context, payload) {
    const response = await axios.post(
      "api/facturacionOS/getComprobantesFacturacionOS",
      payload
    );
    return response.data.data;
  },
  async getPorcentajesVentaXOs(context, payload) {
    const response = await axios.get(
      `api/osPorcVenta/porcentajes-venta-os?vigenteAl=${payload}`
    );
    return response.data.data;
  },
  async getPorcentajeVentaXOsById(context, payload) {
    const response = await axios.get(
      `api/osPorcVenta/porcentaje-venta-os/${payload}`
    );
    return response.data.data;
  },
  async deletePorcentajeVentaXOs(context, payload) {
    const response = await axios.delete(
      `api/osPorcVenta/porcentaje-venta-os/${payload}`
    );
    return response;
  },
  async savePorcentajeVentaXOs(context, payload) {
    const response = await axios.post(
      `api/osPorcVenta/porcentaje-venta-os`,payload
    );
    return response;
  },
};
export default actions;
