import axios from "@/axios/axios-api";
import getAPIUrl from "@/utils/helpers/getAPIUrl";

const actions = {
  async getTipoOperacion() {
    const response = await axios.get(`api/taxonomy/GetTipoOperacion`);
    return response.data.data;
  },
  async getParametroGralCargaFacturaEnCero() {
    const response = await axios.get(
      `api/ParametrosGenerales/get-parametrogral-cargafact`
    );
    return response.data.data;
  },
  async getCondicionesGanancias() {
    const response = await axios.get(`api/taxonomy/condiciones-ganancias`);
    return response.data.data;
  },
  async getConceptosGanancias() {
    const response = await axios.get(`api/taxonomy/conceptos-ganancias`);
    return response.data.data;
  },
  async getTiposImputacion() {
    const response = await axios.get(`api/taxonomy/tipos-imputacion`);
    return response.data.data;
  },
  async getRetencionesOtroImp() {
    const response = await axios.get(`api/taxonomy/retenciones-otro-imp`);
    return response.data.data;
  },
  async getTipoCuentasBancarias() {
    const response = await axios.get(`api/taxonomy/tipo-cuenta-bancaria`);
    return response.data.data;
  },
  async getJurisdiccionesIIBBEntidadesFacturantes() {
    const response = await axios.get(`api/taxonomy/jurisdicciones-iibb-entidades-facturantes`);
    return response.data.data;
  },
  async getTiposValoresRetIvaIbMun() {
    const response = await axios.get(`api/taxonomy/tipos-valores-by-tipo-retencion`);
    return response.data.data;
  },
  async getTipoValoresRetenciones() {
    const response = await axios.get(`api/taxonomy/tipo-valores-retenciones`);
    return response.data.data;
  },
  async getTiposNomencladores() {
    const response = await axios.get(`api/taxonomy/tipos-nomencladores`);
    return response.data.data;
  },
  async getTiposOrden() {
    const response = await axios.get(`api/taxonomy/tipos-orden`);
    return response.data.data;
  },
};

export default actions;
