const icons = Object.freeze({
  DEFAULT_ICON: "mdi-cancel",
  EDIT: "mdi-pencil",
  DELETE: "mdi-trash-can-outline",
  CLOSE_FILTERS: "mdi-tune",
  CLEAR_FILTERS: "mdi-filter-remove-outline",
  CALENDAR: "mdi-calendar",
  TIME: "mdi-clock-time-four-outline",
  UPDATE: "mdi-swap-horizontal-circle-outline",
  ATTACH: "mdi mdi-paperclip",
  HISTORIC: "mdi-history",
  SNB_SUCCESS: "mdi-check-circle",
  SNB_ERROR: "mdi-alert-octagon-outline",
  SNB_INFO: "mdi-information-outline",
  SNB_WARNING: "mdi-alert-outline",
  BACK: "mdi-arrow-left",
  SEARCH: "mdi-magnify",
  CHEVRON_DOWN: "mdi-chevron-down",
  CHEVRON_UP: "mdi-chevron-up",
  EMAIL_CHECK_OUTLINE: "mdi-email-check-outline",
  ACCOUNT_CHECK_OUTLINE: "mdi-account-check-outline",
  FILE_SEND: "mdi-file-send",
  FILE_CHECK: "mdi-file-check",
  EMAIL_SEND_OUTLINE: "mdi-email-arrow-right-outline",
  ACCOUNT_TIE: "mdi-account-tie",
  ACCOUNT_PLUS: "mdi-account-plus",
  SEE: "mdi-eye",
  CHECK_OUTLINE: "mdi-check-outline",
  CHECK: "mdi-check",
  PDF_EXPORT: "mdi-file-download-outline",
  DOWNLOAD: "mdi-download",
  CLOSE_OUTLINE: "mdi-close-outline",
  CLOSE: "mdi-close",
  EXPORT_EXCEL: "mdi-microsoft-excel",
  CIRCLE_ADD_ITEM: "mdi-plus-circle-outline",
  MORE_OPTIONS: "mdi-dots-horizontal-circle-outline",
  MORE_ITEMS: "mdi-chevron-down",
  CLOSE_WINDOW: "mdi-close-circle-outline",
  PERCENT_OUTLINE: "mdi-percent-outline",
  COPY: "mdi-content-copy",
  SETTINGS: "mdi-cog",
  CLOSE_BOX: "mdi-close-box",
  CHECKBOX_BLANK_OUTLINE: "mdi-checkbox-blank-outline",
  PERCENT: "mdi-percent-outline",
  SUCURSAL: "mdi-domain",
  HELP: "mdi-help-circle",
  DOWN_ARROW: "mdi-arrow-down-thin",
  ARROW_EXPAND: "mdi-arrow-expand",
  BUSINESS: "mdi-domain",
  MONEY: "mdi-cash-multiple",
  ARROW_COLLAPSE: "mdi-arrow-collapse",
  ACCOUNT_MOVEMENT: "mdi-account-arrow-right",
  ICON_CONDICION_IVA: "mdi-file-percent-outline",
  USER_SQUARE: "mdi-account-box",
  CIRCLE_NONE: "mdi-minus-circle",
  GROUP: "mdi-account-group",
  MAP_MARKER: "mdi-map-marker-multiple",
  CHECK_BOX: "mdi-checkbox-marked",
  TREE_DOT: "mdi-dots-horizontal-circle",
  OPEN_CANDADO: "mdi-lock-open",
  CLOSE_CANDADO: "mdi-lock",
  RELOAD: "mdi-reload",
  ENVIAR_EMAIL_ICONO: "mdi-email-mark-as-unread",
  ACCOUNT: "mdi-account",
  ACCOUNT_CHECK: "mdi-account-check",
  OPEN_ARCHIVO: "mdi-clipboard-arrow-down",
  STETHOSCOPE: "mdi-stethoscope",
  MULTIPLE_CHECK: "mdi-checkbox-multiple-marked-outline",
  VERPROP: "mdi-account-outline",
  ASIGN_ICON: "mdi-playlist-check",
  BTN_LOAD: "mdi-calculator-variant",
  FILE_CANCEL: "mdi-file-cancel",
  SHIELD_KEY_ICON: "mdi-shield-key",
  QRCODE_REMOVE: "mdi-qrcode-remove",
  RESTORE_ICON: "mdi-backup-restore",
  MENU_ICON: "mdi-menu",
  MICRO: "mdi-microphone",
  EDIT_CIRCLE: "mdi-pencil-circle",
  FORMAT_BOLD: "mdi-format-bold",
  FORMAT_ITALIC: "mdi-format-italic",
  FORMAT_UNDERLINE: "mdi-format-underline",
  FORMAT_STRIKE: "mdi-format-strikethrough-variant",
  FORMAT_CLEAR: "mdi-format-clear",
  UNDO: "mdi-undo",
  REDO: "mdi-redo",
  ADD_IMAGE: "mdi-image-plus",
  OL: "mdi-format-list-numbered",
  UL: "mdi-format-list-bulleted",
  CODE: "mdi-xml",
  QUOTE: "mdi-format-quote-close",
  REMOVE: "mdi-minus",
  LIST_CHECKBOX: "mdi-format-list-checkbox",
  ALIGN_LEFT: "mdi-format-align-left",
  ALIGN_RIGHT: "mdi-format-align-right",
  ALIGN_CENTER: "mdi-format-align-center",
  ALIGN_JUSTIFY: "mdi-format-align-justify",
  TABLE_ADD: "mdi-table-plus",
  COLOR_TEXT: "mdi-format-color-text",
  COLOR_FILL: "mdi-format-color-fill",
  LINK: "mdi-link-variant",
  LINK_PLUS: "mdi-link-variant-plus",
  LINK_MINUS: "mdi-link-variant-minus",
  CHEVRON_RIGHT: "mdi-chevron-right",
  INDENT: "mdi-format-indent-increase",
  OUTDENT: "mdi-format-indent-decrease",
  ERASER: "mdi-eraser",
  FULL_SCREEN: "mdi-fullscreen",
  CURRENCY_USD: "mdi-currency-usd",
  PAPER_WITH_PENCIL: "mdi-note-edit-outline",
  PAPER_CHECK: "mdi-note-check-outline",
  PAPER_MINUS: "mdi-note-minus-outline",
  REVERSE: "mdi-undo-variant",
  THREE_VERTICAL_POINTS: "mdi-dots-vertical",
  TABLE_ARROW_UP: "mdi-table-arrow-up",
});

export default icons;
