const titles = Object.freeze({
  CONFIGURACION_CARTILLA: "Configuración de cartilla",
  OPCIONES_MENU_TABS: "Opciones de menú y tabs",
  EDITAR_PRESTADORES_CARTILLA: "Editar información para cartilla",
  SET_ADDRESS_ON_MAP: "Editar domicilio",
  GESTION_PROCESOS_FISCALIZACION: "Gestión de procesos de fiscalización",
  EDITAR_PROCESO_FISCALIZACION: "Editar proceso de fiscalización",
  EDITAR_ACTA: "Editar acta",
  EDITAR_ESTADO_ACTA: "Actualizar estado de acta",
  HISTORIAL_ESTADO_ACTA: "Historial de estados del acta",
  NUEVO_PROCESO_FISCALIZACION: "Nuevo proceso de fiscalización",
  EDITAR_PROCESO_FISCALIZACION: "Editar proceso de fiscalización",
  DIALOG_ELIMINAR: "¿Eliminar?",
  DIALOG_CONFIRMAR: "Atención",
  ENLACES_UTILES: "Enlaces útiles",
  TIPOS_INTERES: "Tipos de interés",
  NUEVO_TIPO_INTERES: "Nuevo tipo de interés",
  EDITAR_TIPO_INTERES: "Editar tipo de interés",
  ESTADOS_ACTAS_FISCALIZACION: "Estados de actas",
  NUEVO_ESTADO_ACTA: "Nuevo estado de acta",
  EDITAR_ESTADO_ACTA: "Editar estado de acta",
  AFILIACIONES_CONFIG: "Configuración de afiliaciones",
  GESTION_INFORME_PROCESOS_AFIP: "Informe de fiscalización a AFIP",
  CONFIGURACION_FORMATOS_EMAIL_ACTAS:
    "Configuración de formatos email de actas",
  CONFIGURACION_FORMATOS_PDF_ACTAS: "Configuración de formatos pdf de actas",
  NUEVO_FORMATO_EMAIL: "Nuevo formato de email",
  EDITAR_FORMATO_EMAIL: "Editar formato de email",
  NUEVO_FORMATO_PDF: "Nuevo formato de pdf",
  EDITAR_FORMATO_PDF: "Editar formato de pdf",
  GESTION_PROCESOS_AFIP: "Gestión de informes de fiscalización a AFIP",
  GENERACION_ARCHIVO_AFIP: "Nuevo archivo de fiscalización a AFIP",
  DETALLE_ARCHIVO_AFIP: "Detalle de archivo",
  EMAIL_DETAIL_DIALOG: "Historial de envíos por email",
  HISTORIAL_EMAIL_ACTAS: "Historial de envíos de actas por email",
  CONFIRM_SEND_EMAIL_DIALOG: "Notificar por email",
  ALERT_ITEMS_NEXT_PAGE:
    "Existen actas sin seleccionar en la siguiente página. ¿Desea continuar?",
  EXPORTAR_PDF: "Exportar a PDF",
  EDITAR_ESTADO: "Editar estado de acta",
  ALTA_USUARIO_EMAIL: "Formato de email nueva cuenta de usuario",
  RECUPERAR_CONTRASEÑA_EMAIL: "Formato de email para recuperar contraseña",
  DATOS_ARCHIVO_AFIP: "Datos del archivo",
  SENT_EMAIL_DIALOG: "Resultado de envíos",
  CONFIGURACION_PERMISOS: "Permisos de acceso",
  CONFIGURACION_PERMISOS_CARD_TITLE:
    "Seleccione un grupo para configurar opciones de menú y permisos.",
  SERVIDOR_EMAIL: "Configuración de servidores de email",
  NUEVO_SERVIDOR_EMAIL: "Nuevo servidor de email",
  EDITAR_SERVIDOR_EMAIL: "Editar servidor de email",
  SEND_TEST_EMAIL_SERVIDOR: "Enviar email de prueba de servidor",
  SECTORES_MESA_ENTRADA: "Sectores",
  DESTINOS_DESPACHOS_MESA_ENTRADA: "Destinos para despachos",
  TIPOS_COMPROBANTES_COBRO: "Tipos de comprobante para cobro de aportes",
  EDITAR_TIPOS_COMPROBANTES_COBRO: "Editar tipo de comprobante",
  VENDEDORES: "Vendedores",
  NUEVO_TIPOS_COMPROBANTES_COBRO:
    "Nuevo tipo de comprobante para el cobro de aportes",
  CONFIRMAR_REGRESO:
    "Al confirmar perderá los datos sin guardar. ¿Desea continuar?",
  PARENTESCOS_APP: "Parentescos",
  DIALOG_GEOLOCALIZACION_MASIVA_TITLE:
    "¿Generar geolocalización para todos los registros?",
  DIALOG_GEOLOCALIZACION_MASIVA:
    "Se intentará ubicar geográficamente cada registro que no haya sido ya geolocalizado manual e individualmente.",
  SECOND_DIALOG_GEOLOCALIZACION_TITLE:
    "¿Confirmar proceso de gelocalización masiva?",
  SECOND_DIALOG_GEOLOCALIZACION: "Esta operación puede tardar unos minutos.",
  CREACION_LOTE_DEUDA: "Nuevo lote de deuda",
  IDLE_DIALOG: "Sesión a punto de expirar",
  NUEVO_ENVIO_ENTIDAD_COBRANZA:
    "Nueva configuración de envío a entidad de cobranza",
  CONFIGURACION_FORMATOS_EMAIL_APP:
    "Configuración de formatos email de aplicación móvil",
  CONFIGURACION_FISCALIZACION_DE_APORTES:
    "Configuración de fiscalización de aportes",
  CONFIGURACION_APP: "Aplicaciones publicadas",
  NUEVA_CONFIGURACION_APP: "Nueva aplicación",
  EDITAR_CONFIGURACION_APP: "Editar aplicación",
  ELIMINAR_CONFIGURACION_APP: "Eliminar aplicación",
  CONFIGURACION_PARAMETROS_GENERALES: "Configuración de parámetros",
  CONFIGURACION_APP_BENEF: "Configuración de aplicación móvil",
  TIPOS_DE_EXPEDIENTES: "Tipos de expedientes habilitados",
  NUEVO_TIPOS_DE_EXPEDIENTES: "Configuración de carga externa de expedientes",
  EDITAR_TIPOS_DE_EXPEDIENTES: "Configuración de carga externa de expedientes",
  CONFIGURACION_MESA_ENTRADA: "Configuración de mesa de entrada",
  FORMAS_PAGO: "Formas de pago",
  FORMAS_PAGO_ENTIDADES_COBRANZA: "Formas de pago y entidades de cobranza",
  ENTIDADES_FINANCIERAS_APP: "Entidades financieras",
  GENERADORES_DE_EXPEDIENTES: "Generadores de expedientes",
  TIPOS_DE_GENERADORES_EXPEDIENTES: "Tipos de generadores de expedientes",
  DEVENGAMIENTOS_COBRANZAS: "Devengamientos y cobranzas",
  CONSULTA_COBRADORES: "Cobradores",
  EDITAR_COBRADOR: "Editar cobrador",
  NUEVO_COBRADOR: "Nuevo cobrador",
  CONDICIONES_IVA: "Condiciones de IVA",
  VENCIMIENTO_CUIT: "Vencimientos por CUIT",
  EDITAR_COND_IVA: "Editar condición de IVA",
  EDITAR_VENC_CUIT: "Editar vencimiento CUIT",
  NUEVO_VENC_CUIT: "Nuevo vencimiento de CUIT",
  ELIMINAR_COND_IVA: "Eliminar condición de IVA",
  NUEVA_COND_IVA: "Nueva condición de IVA",
  CONCEPTOS_APP: "Conceptos e impuestos",
  CONCEPTOS_APP_DELETE: "¿Eliminar concepto?",
  PLANES: "Planes",
  DELETE_PLAN: "¿Eliminar plan?",
  NUEVO_PLAN: "Nuevo plan",
  EDITAR_PLAN: "Editar plan",
  CONFIGURACION_PLANES: "Configuración del plan",
  DELETE_CONFIGURACION_PLAN: "¿Eliminar configuración?",
  EDITAR_CONFIGURACION_PLAN: "Editar configuración del plan",
  NUEVA_CONFIGURACION_PLAN: "Nueva configuración del plan",
  NUEVA_NACIONALIDAD: "Agregar nacionalidad",
  EDITAR_NACIONALIDAD: "Editar Nacionalidad",
  NACIONALIDADES: "Nacionalidades",
  DELETE_NACIONALIDAD: "¿Eliminar nacionalidad?",
  TIPOS_DE_DOCUMENTOS: "Tipos de documentos",
  TABLA_AFILIADOS: "Tablas de afiliados",
  WEB_PARENTESCOS: "Parentescos",
  WEB_DELETE_PARENTESCO: "¿Eliminar parentesco?",
  WEB_NEW_PARENTESCO: "Nuevo parentesco",
  MOTIVO_DE_BAJA: "Motivo de baja / Cambio de plan",
  ESTADOS_CIVILES: "Estados civiles",
  NUEVO_ESTADO_CIVIL: "Nuevo estado civil",
  EDITAR_ESTADO_CIVIL: "Editar estado civil",
  DELETE_ESTADO_CIVIL: "¿Eliminar estado civil?",
  MOTIVOS_DE_BAJA: "Motivos de baja / Cambio de plan",
  EDITAR_MOTIVO_DE_BAJA: "Editar motivo de baja / Cambio de plan",
  NUEVO_MOTIVO_DE_BAJA: "Nuevo motivo de baja / Cambio de plan",
  NUEVO_TIPO_BENEF: "Nuevo beneficiario",
  EDITAR_TIPO_BENEF: "Editar tipo de beneficiario",
  PROCESOS_DEVENGAMIENTO: "Devengamientos de cuotas",
  DEPENDENCIAS: "Dependencias",
  BOTON_DE_PAGO: "Configuración del botón de pago",
  TOKEN: "Token para autorizaciones de prestadores",
  BENEFICIARIOS: "Tipos de beneficiarios",
  DELETE_BENEF: "¿Eliminar beneficiario?",
  TIPOS_AFILIACIONES: "Otros tipos de afiliación",
  REGLAS_COMERCIALES: "Reglas comerciales",
  NUEVO_TIPO_AFILIACION: "Nuevo tipo de afiliación",
  EDITAR_TIPO_AFILIACION: "Editar tipo de afiliación",
  CONSULTA_DDJJ_APORTES: "DDJJ y aportes",
  TIPOS_COMPROBANTES_DEVENGAMIENTO: "Tipos de comprobantes para devengar",
  EDITAR_RG_COMERCIAL: "Editar regla comercial",
  NUEVA_RG_COMERCIAL: "Nueva regla comercial",
  CAJEROS: "Cajeros",
  NUEVA_CAJA: "Nueva caja",
  EDIT_CAJA: "Editar caja",
  DELETE_CAJA: "¿Eliminar caja?",
  EDIT_CONCEPTO: "Editar concepto",
  NUEVO_CONCEPTO: "Nuevo concepto",
  EDIT_PARENTESCO: "Editar Parentescos",
  DELETE_TIPO_AFILIACION: "¿Eliminar tipo de afiliación?",
  EDIT_DEPENDENCIA: "Editar dependencia",
  NUEVA_DEPENDENCIA: "Nueva dependencia",
  DELETE_DEPENDENCIA: "¿Eliminar dependencia?",
  EDIT_TIPO_DOC: "Editar tipo de documento",
  DELETE_TIPO_DOC: "¿Eliminar tipo de documento?",
  IMPUESTOS: "Impuestos",
  EDIT_IMPUESTO: "Editar impuesto",
  NUEVO_IMPUESTO: "Nuevo impuesto",
  DELETE_IMPUESTO: "¿Eliminar impuesto?",
  SOLICITUDES_DE_AFILIACION: "Solicitudes de afiliación",
  TIPOS_DE_SOLICITUD: "Tipos de solicitud",
  CONFIGURACION_TIPO_SOLICITUD:
    "Configuración de estados del tipo de solicitud",
  ELIMINAR_TIPO_DE_SOLICITUD: "¿Eliminar solicitud?",
  OTRAS_AFILIACIONES_AFILIADO: "Otras afiliaciones",
  DELETE_OTRAS_AFILIACIONES_AFILIADO: "¿Eliminar afiliación?",
  EDIT_OTRAS_AFILIACIONES_AFILIADO: "Editar afiliación",
  NEW_OTRAS_AFILIAICONES_AFILIADO: "Nueva afiliación",
  TIPO_DE_SOLICITUD: "Tipo de solicitud",
  RNOS: "Obras sociales del RNOS",
  NUEVA_OBRA_SOC_RNOS: "Nueva Obra Social del RNOS",
  EDITAR_OBRAS_SOC_RNOS: "Editar obra social del RNOS",
  NUEVA_DELEGACION_RNOS: "Nueva delegación",
  EDITAR_DELEGACION_RNOS: "Editar delegación",
  NUEVO_REGIMEN_RNOS: "Nuevo regímen",
  EDITAR_REGIMEN_RNOS: "Editar regímen",
  DELETE_BENEFICIO_ANSES: "¿Eliminar beneficio?",
  BENEFICIOS_AFILIADO: "Beneficios ANSES",
  NUEVO_BENEFICIO_AFILIADO: "Nuevo beneficio",
  EDIT_BENEFICIO_AFILIADO: "Editar beneficio",
  CONFIGURACION_DDJJ_APORTES: "Configuración de DDJJ y aportes",
  DESCUENTOS_APORTES_CONTRIBUCIONES: "Descuentos en aportes y contribuciones",
  EDIT_DESCUENTOS_APORTES_CONTRIBUCIONES:
    "Editar descuento en aportes y contribuciones",
  NUEVO_DESCUENTOS_APORTES_CONTRIBUCIONES:
    "Nuevo descuento en aportes y contribuciones",
  DELETE_DESCUENTOS_APORTES_CONTRIBUCIONES: "¿Eliminar descuento?",
  CONCEPTOS_AFIP: "Conceptos AFIP",
  NUEVO_CONCEPTO_AFIP: "Nuevo concepto AFIP",
  EDITAR_CONCEPTO_AFIP: "Editar concepto AFIP",
  DELETE_CONCEPTO_AFIP: "¿Eliminar concepto?",
  BUSCAR_AGENTE_CUENTA: "Búsqueda de agentes de cuenta",
  EDIT_BENEFICIO_AFILIADO: "Editar beneficio",
  EMPRESAS_Y_SUCURSALES: "Empresas",
  TOPES_EN_REMUNERACIONES: "Topes en remuneraciones para cálculos de aportes",
  DELETE_REMUNERACION: "¿Eliminar remuneración?",
  EDITAR_REMUNERACION: "Editar remuneración",
  NUEVA_REMUNERACION: "Nueva remuneración",
  NUEVA_CONFIGURACION_INGRESOS_TOPE: "Nueva configuración para tope en cuota",
  EDITAR_CONFIGURACION_INGRESOS_TOPE: "Editar configuración para tope en cuota",
  VALORES_CAP_ANSES_JUBILADOS: "Valores de cápitas ANSES para jubilados",
  EDITAR_VALOR_CAPITA_ANSES_JUBILAC:
    "Editar valor de cápita ANSES para jubilados",
  NUEVO_VALOR_CAPITA_ANSES_JUBILAC:
    "Nuevo valor de cápita ANSES para jubilados",
  PADRON_MENSUAL_SSS: "Configuración padrón mensual SSS",
  TEXTO_AYUDA: "Texto de ayuda al usuario",
  VALORES_CAP_ANSES_DESEMPLEO: "Valores de ANSES para desempleo",
  EDITAR_VALOR_CAPITA_ANSES_DESEMPLEO: "Editar valor de ANSES para desempleo",
  NUEVO_VALOR_CAPITA_ANSES_DESEMPLEO: "Nuevo valor de ANSES para desempleo",
  TABLAS_REMUNERACIONES_MINIMAS: "Tablas de remuneraciones mínimas",
  NUEVA_TABLA_REMUNERACIONES_MINIMAS: "Nueva tabla de remuneraciones mínimas",
  EDITAR_TABLA_REMUNERACIONES_MINIMAS: "Editar tabla de remuneraciones mínimas",
  NUEVA_REMUNERACION_MINIMA: "Nueva remuneración mínima",
  EDITAR_REMUNERACION_MINIMA: "Editar remuneración mínima",
  NUEVA_RELACION_EMPRESA_REMUNERACION: "Nueva empresa",
  EDITAR_RELACION_EMPRESA_REMUNERACION: "Editar empresa",
  TABLAS_SICOSS: "Tablas SICOSS",
  NUEVA_CONFIG_SICOSS: "Nueva configuración SICOSS",
  EDITAR_CONFIG_SICOSS: "Editar configuración SICOSS",
  GERENCIADORAS_Y_NIV_ATENCION: "Gerenciadoras y niveles de atención",
  EDITAR_GERENCIADORA: "Editar gerenciadora",
  NUEVA_GERENCIADORA: "Nueva gerenciadora",
  EDITAR_NIVEL_ATENCION: " Editar nivel de atención",
  NUEVO_NIVEL_ATENCION: "Nuevo nivel de atención",
  NUEVO_MOV_PADRON_SSS: "Incluir afiliado en padrón SSS",
  EDITAR_MOV_PADRON_SSS: "Editar afiliado en padrón SSS",
  FORZAR_MOV_PADRON_SSS: "Forzar movimiento padrón SSS",
  EDITAR_GERENCIADORA_AFILIADO: "Editar gerenciadora del afiliado",
  NUEVO_GERENCIADORA_AFILIADO: "Nueva gerenciadora del afiliado",
  GERENCIADORAS_AFILIADO: "Gerenciadoras del afiliado",
  PROCESOS_COBRANZA: "Procesos de cobranza",
  CONFIGURACION_DATOS_ENVIO: "Configuración de datos del proceso",
  TIPO_COMPROB_POR_COND_IVA:
    "Tipo de comprobante de cobro por condición de IVA",
  NUEVO_PROCESO_COBRANZA: "Nueva configuración de proceso de cobranza",
  EDITAR_PROCESO_COBRANZA: "Editar configuración del proceso de cobranza",
  NUEVA_CONFIG_DATOS_ENVIO: "Nueva configuración de datos del proceso",
  EDITAR_CONFIG_DATOS_ENVIO: "Editar datos del proceso",
  EDITAR_CONFIG_TIPO_COMP_IVA: "Editar comprobante por condición de IVA",
  NUEVA_CONFIG_TIPO_COMP_IVA: "Nuevo comprobante por condición de IVA",
  PROCESAR_COBRANZAS: "Procesar cobranzas",
  CONCEPTOS_A_DEVENGAR: "Conceptos a devengar",
  CONCEPTOS_A_DEVENGAR_UNICA_VEZ: "Conceptos a devengar por única vez",
  CONCEPTOS_A_DEVENGAR_PERIODO: "Conceptos a devengar por período",
  NUEVO_CONCEPTO_A_DEVENGAR_UNICA_VEZ:
    "Nuevo concepto a devengar por única vez",
  EDITAR_CONCEPTO_A_DEVENGAR_UNICA_VEZ:
    "Editar concepto a devengar por única vez",
  NUEVO_CONCEPTO_A_DEVENGAR_PERIODO: "Nuevo concepto a devengar por período",
  EDITAR_CONCEPTO_A_DEVENGAR_PERIODO: "Editar concepto a devengar por período",
  CONFIGURACION_ADM_SISTEMA: "Configuración de administración del sistema",
  GESTION_PERMISOS: "Gestión de permisos",
  PANTALLA_LOGIN_ADMINISTRACION_SISTEMA: "Pantalla de login",
  GESTION_AFI_CODIFICADO: "Gestión de afiliados codificados",
  PROCESOS_DEVENGAMIENTOS: "Procesos de devengamientos",
  DEVENGAMIENTO_COSEGUROS: "Configuración del devengamiento de coseguros",
  NUEVA_CONFIGURACION_COSEGUROS: "Nuevo devengamiento de coseguro",
  EDITAR_CONFIGURACION_COSEGUROS: "Editar devengamiento de coseguro",
  DEVENG_COSEGUROS: "Devengamiento de coseguros",
  NUEVO_PROCESO_DEVENG_COSEGURO: "Nuevo proceso de devengamiento de coseguro",
  DEVENGAMIENTO_ADICIONALES: "Devengamiento de adicionales",
  SOLICITUD_AUTORIZACION: "Consulta de autorización del N° de órden",
  IMPORTES_DEVENGAR: "Importes a devengar",
  CONFIG_DDJJ_INGRESOS_PARA_TOPES_EN_CUOTAS:
    "Configuración de DDJJ de ingresos para topes en cuotas",
  DELETE_DDJJ_TOPES_EN_CUOTAS: "¿Eliminar valores ingresados?",
  ADICIONALES_EXTRA: "Adicionales extra",
  DELETE_ADICIONAL_EXTRA: "¿Eliminar adicional?",
  ACTUALIZACION_APORTES_ESP: "Actualización de aportes esperados",
  NUEVA_ACTUALIZACION_AP_ESPERADO: "Nueva actualización de aportes esperados",
  ACTUALIZACION_TABLAS_FACTURACION:
    "Procesos de actualización de tablas de facturación",
  MOVIMIENTO_MASIVO: "Movimientos masivos de padrones",
  CONFIG_MOVIMIENTOS_MASIVOS:
    "Configuración de movimientos masivos de padrones",
  ACTUALIZACION_ADICIONALES_EXTRA: "Procesos de actualización de adicionales",
  NUEVO_ACTUALIZACION_ADICIONALES_EXTRA:
    "Nuevo proceso de actualización de adicionales",
  NUEVO_MOV_MASIVO_PADRONES: "Nuevo proceso de movimientos masivos de padrones",
  ORDENES_DE_PAGO: "Órdenes de pago",
  VER_ORDEN_DE_PAGO: "Ver orden de pago",
  CONFIGURACION_ORDENES_DE_PAGO: "Configuración de órdenes de pago",
  FORMATOS_IMPRESION_ORDENES_DE_PAGO:
    "Formatos de impresión de órdenes de pago y retenciones",
  FORMATOS_EMAIL_ORDENES_DE_PAGO:
    "Formatos de email de órdenes de pago y retenciones",
  CONSULTA_PROC_DESREGULACION: "Procesos de desregulación",
  CONFIG_CONCEPTO_DESREGULACION: "Conceptos de desregulación",
  EDIT_CONCEPTO_DESREGULACION: "Editar concepto de desregulación",
  SEE_CONCEPTO_DESREGULACION: "Ver concepto de desregulación",
  CONFIG_COMISIONES_CONC_DESREGULACION: "Comisiones de conceptos",
  EDIT_COMISION_CONC_DESREGULACION: "Editar comisión de concepto",
  NUEVO_PROCESO_DESREG: "Nuevo proceso de desregulación",
  ADD_CONCEPTO: "Agregar concepto",
  EDITAR_PROCESO_DESREGULACION: "Editar proceso de desregulación",
  VER_PROCESO_DESREG: "Detalle del proceso de desregulación",
  VER_CONCEPTO_PROCESO: "Concepto del proceso de desregulacion",
  VER_CONCEPTO_PROCESO_DESREG: "Detalle del concepto del proceso",
  NUEVO_FORMATO_IMPRESION: "Nuevo formato de impresión",
  EDITAR_FORMATO_IMPRESION: "Editar formato de impresión",
  ENVIO_MAIL_OP: "Envío masivo de OP por mail",
  ENVIAR_EMAIL:
    "¿Desea crear un proceso de envío masivo de email para los registros seleccionados en la grilla?",
  SEND_EMAIL_INDIVIDUAL:
    "¿Desea enviar el comprobante orden de pago por email?",
  CONFIG_BAJAS_AUTOMATICAS: "Configuración de bajas automáticas",
  GRUPOS_DE_DEVENGAMIENTO: "Grupos de devengamiento",
  DELETE_GRUPO_DEVENGAMIENTO: "¿Eliminar grupo de devengamiento?",
  EDIT_GRUPO_DEVENGAMIENTO: "Editar grupo de devengamiento",
  NUEVO_GRUPO_DEVENGAMIENTO: "Nuevo grupo de devengamiento",
  CARGA_APORTES: "Procesos de distribución de aportes ",
  CONSULTA_BAJAS_AUTOMATICAS: "Consulta de bajas automáticas",
  TIPO_COBERTURA_ESPECIAL: "Tipos de coberturas especiales",
  DOCUMENT_TIPO_COBERTURA: "Documentación del tipo de cobertura",
  DELETE_DOCUMENT_TIPO_COBERTURA: "¿Eliminar documentación?",
  EDIT_DOCUMENT_TIPO_COBERTURA: "Editar documentación",
  NEW_DOCUMENT_TIPO_COBERTURA: "Nueva documentación",
  DELETE_COSEGURO_TIPO_COBERTURA: "¿Eliminar coseguro?",
  COSEGURO_TIPO_COBERTURA: "Coseguro en tipo de cobertura especial",
  HEREDAR_DOCUMENT_TIPO_COBERTURA:
    "Heredar documentación de un tipo de cobertura especial",
  TIPO_PRESTADORES: "Tipos de prestadores",
  ESPECIALIDADES: "Especialidades",
  DELETE_ESPECIALIDAD: "¿Eliminar especialidad?",
  EDIT_ESPECIALIDAD: "Editar especialidad",
  NEW_ESPECIALIDAD: "Nueva especialidad",
  COBERTURAS_ESPECIALES: "Coberturas especiales",
  EDIT_COBERTURA_ESPECIAL: "Editar cobertura especial",
  NUEVA_COBERTURA_ESPECIAL: "Nueva cobertura especial",
  DELETE_COBERTURA_ESPECIAL: "¿Eliminar cobertura especial?",
  VER_COBERTURAS_ESPECIALES: "Detalle de cobertura especial por afiliado",
  BUSCAR_AFILIADO: "Buscar afiliado",
  CONFIGURACION_PRESTADORES: "Configuración de prestadores",
  EDITAR_DOC: "Editar documentación",
  CONFIGURACION_CONVENIOS: "Configuración convenios",
  DELETE_ARCHIVO_DOCUMENTACION: "¿Desear eliminar el archivo adjunto?",
  EDIT_RELACION_CONVENIO_EMPRESAS: "Editar relación",
  RELACION_DE_CONVENIOS_EMPRESAS: "Relación entre convenios y empresas",
  TOPES_POR_TIPO_COBERTURA:
    "Mantenimiento de topes por tipo de cobertura especial",
  DELETE_TOPE_POR_TIPO_COBERTURA: "¿Eliminar tope?",
  EDIT_TOPE_POR_TIPO_COBERTURA: "Editar tope de cobertura especial",
  NUEVO_TOPE_POR_TIPO_COBERTURA: "Nuevo tope para cobertura especial",
  NUEVA_ACTA: "Acta sin DDJJ",
  NUEVO_CONCEPTO: "Nuevo concepto",
  CONFIGURACION_COTIZADOR: "Configuración del cotizador",
  FORMATOS_IMPRESION_COTIZADOR: "Formatos de impresión de cotizaciones",
  FORMATOS_EMAIL_COTIZADOR: "Formatos de email de cotizadores",
  NUEVA_ACT_TABLA_FACTU:
    "Nuevo proceso de actualización de tablas de facturación",
  IMPORTES_FIJO_GRUPO_FLIAR: "Importes fijos a devengar por grupo familiar",
  ACTUALIZACION_IMPORTES_FIJOS: "Proceso de actualización de importes fijos",
  NUEVA_ACTUALIZACION_IMPORTES_FIJOS:
    "Nuevo proceso de actualización de importes fijos",
  CONSULTA_TABLA_FAC: "Tablas de facturación",
  COTIZADOR: "Cotizador",
  DELETE_COTIZACION: "¿Desea eliminar la cotización?",
  ASIGNACION_TABLA_FAC: "Asignación de la tabla",
  GRUPOS_ENTIDADES_EFECTORAS: "Grupos de entidades efectoras",
  DELETE_GRUPOS_ENTIDADES_EFECTORAS: "¿Desea eliminar el grupo?",
  DETALLE_ASIGNACION: "Detalle de procesos de asignación",
  REGISTROS_APP: "Usuarios registrados en la aplicación móvil",
  DOBLE_FACTOR_AUTENTICACION: "Doble factor de autenticación",
  COMPROBANTES_PROVEEDOR: "Comprobantes proveedor",
  GESTION_USUARIOS: "Gestión de usuarios",
  RESTAURAR_CONTRASEÑA: "¿Desea restaurar la contraseña?",
  TOPES_GRALES: "Topes generales",
  DELETE_TOPE_GRAL: "¿Desea eliminar el tope?",
  CONFIGURACION_PRESTACIONES: "Configuración de prestaciones",
  POLITICA_CONTRASEÑAS: "Política de contraseñas",
  UNIFICAR_AFILIADO: "Unificación de afiliados",
  PROC_ACT_ALFA_BETA: "Alfa Beta",
  CONFIGURACIONES_GENERALES: "Configuraciones generales",
  TITLE_PLANTILLA_CARGA_TRAMITES: "Plantilla de carga de trámites",
  CONFIG_PLANTILLA_CARGA_TRAMITES:
    "Configuración de plantilla de carga de trámites",
  IMPORTAR_FACT: "Proceso de importación de Facturas",
  ELIMINAR_COMPROBANTE: "¿Desea eliminar el comprobante?",
  AUTORIZACIONES_PROVEEDOR: "Autorización de facturas a pagar",
  CONFIG_AUTORIZACIONES_PROVEEDOR: "Configuración de autorización de facturas",
  NIVELES_AUDITORIA: "Niveles de auditoría",
  DELETE_NIVEL_AUDITORIA: "¿Desea eliminar el nivel de auditoría?",
  PROCESOS_AFILIADOS: "Procesos",
  CONFIGURACION_LOTES_DEUDAS: "Configuración lotes de deudas",
  FORMATOS_EMAIL_LOTES_DEUDAS: "Formatos de email de lotes de deudas",
  NUEVO_FORMATO_EMAIL_LOTE_DEUDA: "Nuevo formato de email",
  EDITAR_FORMATO_EMAIL_LOTE_DEUDA: "Editar formato de email",
  ADHESIONES_ENTIDADES_COBRANZA: "Configuración de adhesiones a entidades de cobranza",
  CONFIG_MASCARAS_CARENCIAS: "Configuración de máscaras de carencias",
  DELETE_CONFIG_MASCARA: "Se eliminará la máscara con toda su configuración ¿Desea continuar?",
});

export default titles;
